<template>
  <div class="box-style-analytics mb-2">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <p class="text-uppercase f-12">
        {{ $t(`search_preview.info_twitch.accountAnalysis`) }}
      </p>
      <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
        <span v-if="!is_paid">
          {{ $t('search_preview.unlockReport') }}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
        </span>
        <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
      </b-button>
    </div>

    <!-- Twitch grid analysis -->
    <div class="grid-analytics mb-2" v-if="network === 'twitch'">
      <b-tooltip target="views-info">{{$t("search_preview.info_twitch.tooltipliveViews") }}</b-tooltip>
      <div class="grid-item box-style-analytics">
        <span class="text-muted-left-side-analytics">{{ $t("search_preview.info_twitch.averageLiveViews") }}
          <b-icon
            icon="question-circle"
            id="views-info"
            class="class-question"
          ></b-icon
        ></span>
        <span class="title-result">{{liveViewersAvg}}</span>
      </div>

      <div class="grid-item box-style-analytics">
          <b-tooltip target="transmission-info">{{$t("search_preview.info_twitch.tooltiptransmission7d")}}</b-tooltip> 
        <span class="text-muted-left-side-analytics">{{ $t("search_preview.info_twitch.transmission7d")}}
          <b-icon
            icon="question-circle"
            id="transmission-info"
            class="class-question"
          ></b-icon
        ></span>
        <span class="title-result">{{ hoursStreamed }}</span>
      </div>

      <div class="grid-item box-style-analytics">
        <b-tooltip target="active-info">{{$t("search_preview.info_twitch.tooltipdaysActive")}}</b-tooltip>
        <span class="text-muted-left-side-analytics">{{ $t("search_preview.info_twitch.daysActive")}}
          <b-icon
            icon="question-circle"
            id="active-info"
            class="class-question"
          ></b-icon
        ></span>
        <span class="title-result">{{ activeDaysPerWeek }}</span>
      </div>

      <div class="grid-item box-style-analytics">
        <span class="text-muted-left-side-analytics">{{$t("search_preview.info_twitch.bestStreamingGame")}}</span>
        <span class="title-result">{{bestStreamingGame}}</span>
      </div>
    </div>

    <!-- Twitter grid analysis -->
    <div class="grid-analytics mb-2" v-if="network === 'twitter'">
      <b-tooltip target="views-info">{{$t("profile.tooltipEr") }}</b-tooltip>
      <div class="grid-item box-style-analytics">
        <span class="text-muted-left-side-analytics">ER
          <b-badge class="badge-nivel mb-1" :style="`background-color: ${$t(`profile.colorsMark.${er.mark}`).color}`">{{ $t(`profile.colorsMark.${er.mark}`).text }}</b-badge>
          <b-icon
            icon="question-circle"
            id="views-info"
            class="class-question"
          ></b-icon
        ></span>
        <span class="title-result">{{er.value}}{{ er.value && '%' }}</span>
      </div>

      <div class="grid-item box-style-analytics">
        <b-tooltip target="transmission-info">{{$t("profile.tooltipFrecueny")}}</b-tooltip> 
        <span class="text-muted-left-side-analytics">{{ $t('profile.tweetsFrecuency') }}
          <b-icon
            icon="question-circle"
            id="transmission-info"
            class="class-question"
          ></b-icon
        ></span>
        <span class="title-result">{{ mediaPerWeek }}</span>
      </div>

      <div class="grid-item box-style-analytics">
        <b-tooltip target="active-info">{{$t("profile.tooltipTuits")}}</b-tooltip>
        <span class="text-muted-left-side-analytics">{{ $t('profile.mentionNumber') }}
          <b-icon
            icon="question-circle"
            id="active-info"
            class="class-question"
          ></b-icon
        ></span>
        <span class="title-result">{{bloggerMentions}}</span>
      </div>

      <div class="grid-item box-style-analytics">
        <span class="text-muted-left-side-analytics d-flex align-items-center">{{ $t('instagram.anualGrowth') }}
          <b-badge class="badge-nivel" :style="`background-color: ${$t(`profile.colorsMark.${subscribersGrowth.mark}`).color}`">{{ $t(`profile.colorsMark.${subscribersGrowth.mark}`).text }}</b-badge>
        </span>
        <span class="title-result">{{subscribersGrowth.value}}{{ subscribersGrowth.value && "%" }}</span>
      </div>
    </div>

    <div class="grid-more-info">
      <div
        class="d-flex text-muted-left-side-analytics border-bottom"
        v-for="text in network === 'twitch' ? more_info : more_info_twitter"
        :key="text"
      >
        <feather-icon
          icon="LockIcon"
          class="icon-arrows-prices"
        ></feather-icon>
        <p>{{ text }}</p>
      </div>
    </div>

    <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
      <span v-if="!is_paid">
        {{ $t('search_preview.unlockReport') }}
        <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
      </span>
      <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
    </b-button>
  </div>
</template>
  
<script>
import { getFormat } from "@/libs/utils/formats";
import { redirectToSocialNet } from "@/libs/utils/urls";
import { getIconColor, getIconWhite } from "@/libs/utils/icons";
import { 
  BButton, 
  BImg, 
  BTooltip,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BButton,    
    BImg,
    BTooltip,
    BBadge,
  },
  props: {
    network: {
      type: String,
      required: true
    },
    data_user: {
      type: Object,
      required: true
    },
    is_paid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showMore: false,
      getIconColor,
      getIconWhite,
      getFormat,
      redirectToSocialNet,
      more_info: [
        this.$t("search_preview.info_twitch.liveViewsAvg"),
        this.$t("search_preview.info_twitch.audienceCountries"),
        this.$t("search_preview.info_twitch.ageAndGenderAudience"),
        this.$t("search_preview.info_twitch.transmissions7Days"),
        this.$t("search_preview.info_twitch.chatMessagesHour"),
        this.$t("search_preview.info_twitch.more8Metrics"),
      ],
      more_info_twitter: [
        this.$t("search_preview.info_twitter.followersAndGrowth"),
        this.$t("search_preview.info_twitter.avgEngagementPerTweet"),
        this.$t("search_preview.info_twitter.quotedRetweets"),
        this.$t("search_preview.info_twitter.tweetsPerWeek"),
        this.$t("search_preview.info_twitter.more11Metrics"),
      ],
    };
  },
  computed: {
    // Computed variables that can be undefined
    liveViewersAvg() {
      return this.data_user?.metrics?.live_viewers_avg?.performance?.["30d"]?.value || 'N/A';
    },
    hoursStreamed() {
      return `${this.data_user?.metrics?.hours_streamed?.performance?.["7d"]?.value} ${this.$t('profile.hours')}` || 'N/A';
    },
    activeDaysPerWeek() {
      return `${this.data_user?.metrics?.active_days_per_week?.performance?.["30d"]?.value} ${this.$t('twitter.days')}` || 'N/A';
    },
    bestStreamingGame() {
      return this.data_user?.features?.games?.top?.basic?.title || 'N/A';
    },
    er() {
      return this.data_user?.metrics?.er || 'N/A';
    },
    mediaPerWeek() {
      return this.data_user?.metrics?.media_per_week?.value || 'N/A';
    },
    bloggerMentions() {
      return this.data_user?.features?.blogger_mentions?.data?.mentions?.value || 'N/A';
    },
    subscribersGrowth() {
      return this.data_user?.metrics?.subscribers_growth_prc?.performance?.["365d"] || 'N/A';
    }
  },
};
</script>

<style lang="scss" scoped>

.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 16px;
  background-color: white;
  border: 1px solid #e2e8f0;
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;
  }
}

.text-muted-left-side-analytics {
  font-size: 14px;
  font-family: "avenir-medium";
  color: #718096;
  margin-bottom: 0.3em;
}
.icon-arrows-prices {
  color: #687385;
  margin-right: 0.5em;
  margin-top: 0.2em;
}
.icon-coin {
  width: 20px;
  height: 20px;
}
.grid-analytics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  @media (max-width: 690px) {
    grid-template-columns: repeat(1, 1fr);
  }


  .badge-nivel {
    font-family: 'avenir-medium' !important;
    font-size: 14px;
    margin-left: 0.5em;
    line-height: 1.3;
    font-weight: 500;
  }
  .title-result {
    font-size: 18px;
    width: 100%;
    display: block;
  }
}
.class-question {
  cursor: pointer;
  margin-left: 10px;
}
</style>